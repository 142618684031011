import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { sendFeedback } from './apiclient';

const FeedbackModal = (props) => {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user.user)
    const [narrative, setNarrative] = useState(false)
    const [results, setResults] = useState(false)
    const [narrativeValue, setNarrativeValue] = useState('')
    const [resultsValue, setResultsValue] = useState('')

    const handleChange = (checkedVal) => {
        if (checkedVal === 'narrative') {
            setNarrative(!narrative)
            if (narrative) {
                setNarrativeValue('');
            }
        } else {
            setResults(!results)
            if (results) {
                setResultsValue('');
            }
        }
    }

    const handleNarrativeChange = (event) => {
        setNarrativeValue(event.target.value);
    }

    const handleResultsChange = (event) => {
        setResultsValue(event.target.value);
    }

    const handleSendFeedback = () => {
        const createPayload = (type, value) => ({
            [type]: {
                labels: [`${type}-bad`],
                request_id: props.request_id,
                comment: value
            }
        });

        if (narrative) {
            sendFeedback(dispatch, user.user_id, createPayload('narrative', narrativeValue), 'sad');
        }

        if (results) {
            sendFeedback(dispatch, user.user_id, createPayload('query', resultsValue), 'sad');
        }

        handleCancel();
    };

    const handleCancel = () => {
        setNarrative(false)
        setResults(false)
        setNarrativeValue('')
        setResultsValue('')
        props.handleCancel()
    }

    // Enable Done button only if each checked checkbox has a non-empty corresponding comment
    const isDoneButtonEnabled = () => {
        const narrativeValid = !narrative || (narrative && narrativeValue.length > 0);
        const queryValid = !results || (results && resultsValue.length > 0);
        return narrativeValid && queryValid && (narrative || results);
    };

    return (
        <Modal isOpen={props.isOpen} id="feedbackModal">
            <ModalHeader id='btn-feedback-close' className='d-flex' toggle={props.handleCancel}>
                Just Ask Feedback
            </ModalHeader>
            <ModalBody className="save-model-body">
                <div className="input-container mt-3">
                    <label id="feedback-text" className='mb-3'>Enter feedback on the narrative text (in the blue bubble) and/or the results (such as the accuracy of the search, etc.) using the form below.</label>
                    <div className="check-box-button">
                        <Input type="checkbox" id="narrative-checkbox" checked={narrative} onChange={(e) => handleChange("narrative")} />
                        <Label for="narrative-checkbox" className="form-check-label">Narrative</Label>
                        <p>
                            <Input id="narrative-textarea" type="textarea" value={narrativeValue} name="narrativeValue" disabled={narrative ? false : true} maxLength="2000" onChange={handleNarrativeChange} />
                        </p>
                    </div>
                    <div className="check-box-button">
                        <Input type="checkbox" id="results-checkbox" checked={results} onChange={(e) => handleChange("results")} />
                        <Label for="results-checkbox" className="form-check-label">Results</Label>
                        <p>
                            <Input id="results-textarea" type="textarea" value={resultsValue} name="resultsValue" disabled={results ? false : true} maxLength="2000" onChange={handleResultsChange} />
                        </p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button id="btn-feedback-done" className="primary-btn btn-send" disabled={!isDoneButtonEnabled()} onClick={handleSendFeedback}>Done</Button>
                <Button id="btn-feedback-cancel" className="outline-btn btn-feedback-cancel" onClick={handleCancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default FeedbackModal;