import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input, Container, Row, Col, InputGroup, InputGroupText } from 'reactstrap';
import { FaPaperPlane } from 'react-icons/fa';
import { GoLightBulb } from "react-icons/go";
import { getJustAskSamples, getJustAskResponse, sendFeedback } from './apiclient';
import { fetchSearchListForAllObjectType, resetAllSearch, resetIncludeExcludeIds, rolesForProject, updateBackObjectId, updateHierarchyBack, updateIdsForShowRelatedEntities, updateIsShowProjectsOrCompanySelected, updatelistOfSelectedObjectIds, updateNavigationBackJA, updateNoResult, updateObjectId, updatePeopleProjectsList, updateProjectsForCompanyId, updateRemoveFilterToggleJA, updateSelectedSelectorControlValue, updateShowDetailPeople, updateShowHierarchyForJustAsk, setLoading, updateShowSelectedCompaniesJA, updateShowSelectedProjectsJA, updateEmoji, updateDisplayContent } from './search/redux/reducer';
import { updateContextType, updateFilterState, updateNewSearch, updateOffsetLimit, updateSelectedSearchType } from './search/filters/redux/reducer';
import { addMessage, clearMessagesAfterIndex, resetAll, updatedSelectedSavedSearchJA, updateErrrorInApi, updateFilterQuery, updateJustAskQuery } from './search/redux/searchPeristReducer';
import { updateSavedConversationRequest } from '.././savedsearch/redux/reducer';
import { initializeFilterState } from './search/filters/utils';
import { Link } from 'react-router-dom';
import FeedbackModal from './feedbackModal';

const LeftPanel = () => {
    const dispatch = useDispatch()
    const justAsk = useSelector((state) => state.justAsk)
    const searchPersist = useSelector((state) => state.justAskPersist)
    const settings = useSelector((state) => state.settings)
    const user = useSelector(state => state.user.user)
    const filter = useSelector((state) => state.justAskFilter)
    const savedsearch = useSelector((state) => state.savedsearch)
    const [searchText, setSearchText] = useState('');
    const [examples, setExamples] = useState([]);
    const conversation = useSelector((state) => state.justAskPersist.justAskConversation);
    const loading = useSelector((state) => state.justAskPersist.loading);
    const [highlightIndex, setHighlightIndex] = useState(null);
    const [emoji, setEmoji] = useState('');
    const [modal, setModal] = useState(false);
    const [requestId, setRequestId] = useState('');

    useEffect(() => {
        getJustAskSamples(dispatch)
    }, [])

    useEffect(() => {
        if (conversation?.length > 0) {
            highlightMostRecentAI();
        }
    }, [conversation])

    useEffect(() => {
        if (examples.length === 0) {
            setExamples(justAsk.exampleList)
        }
    }, [justAsk.exampleList])

    useEffect(() => {
        setEmoji(justAsk.emoji)
    }, [justAsk.emoji])

    useEffect(() => {
        if (savedsearch.savedConversationRequest !== '') {
            dispatch(setLoading(true));
            handleApiCall(savedsearch.savedConversationRequest);
            dispatch(updateSavedConversationRequest(""))
        }
    }, [savedsearch.savedConversationRequest])

    const handleSearch = () => {
        if (searchText) {
            dispatch(setLoading(true));
            handleApiCall(searchText);
        }
    };

    const handleNewSearch = () => {
        setSearchText('');
        dispatch(clearMessagesAfterIndex(-1)); // Clear all messages

        // This code is similar to new search click
        dispatch(updateErrrorInApi(false))
        dispatch(resetIncludeExcludeIds([]))
        dispatch(updateSelectedSearchType(searchPersist.appliedContext))
        dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
        dispatch(
            fetchSearchListForAllObjectType({
                leadsFlags: [],
                count: 0,
                filterType: searchPersist.appliedContext,
            })
        );
        dispatch(resetAll());
        dispatch(resetAllSearch());
        if (justAsk.showSelectedCompanies || justAsk.showSelectedProjects) {
            searchPersist.appliedContext === "project"
                ? dispatch(updateShowSelectedCompaniesJA(false))
                : dispatch(updateShowSelectedProjectsJA(false));
            dispatch(updateIsShowProjectsOrCompanySelected(false))
            dispatch(
                updateSelectedSelectorControlValue(justAsk?.selectedLeadsFlag?.length)
            );
        } else {
            dispatch(updateSelectedSelectorControlValue(0));
        }
        dispatch(updateShowDetailPeople(false));
        dispatch(updateHierarchyBack(true));
        dispatch(updateNoResult(false))
        dispatch(updateIdsForShowRelatedEntities([]));
        dispatch(rolesForProject([]));
        dispatch(updatePeopleProjectsList([]));
        dispatch(updateRemoveFilterToggleJA(true));
        dispatch(updateBackObjectId(undefined));
        dispatch(
            updateShowHierarchyForJustAsk({
                showProjectHierarchy: false,
                showCompanyHierarchy: false,
            })
        );
        dispatch(updateShowSelectedCompaniesJA(false));
        dispatch(updateShowSelectedProjectsJA(false));
        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }));
        dispatch(updateObjectId(undefined));
        dispatch(updateNavigationBackJA(""));
        dispatch(updateProjectsForCompanyId([]));
        if (searchPersist.customerProfile) {
            let defaultFilter = initializeFilterState(
                justAsk?.frameworkOptions,
                searchPersist.customerProfile,
                {}
            );
            dispatch(updateFilterState(defaultFilter));
            //dispatch(updateNewSearch(true));
        }
        dispatch(
            updateContextType({
                context: searchPersist.appliedContext,
                searchMode: "basic",
            })
        );
        dispatch(updatedSelectedSavedSearchJA({}));
        dispatch(updateFilterQuery({}));
        dispatch(updateJustAskQuery({}))
        dispatch(updateEmoji(''))
        dispatch(updateDisplayContent(true))
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };
    const handleExampleClick = (example) => {
        setSearchText(example.example);
        handleApiCall(example.example);
    };

    const handleApiCall = (searchText) => {
        dispatch(addMessage({ text: searchText, type: 'user' }));
        dispatch(addMessage({ text: "", type: 'ai' }));
        let userTexts = '';
        if (searchPersist.justAskConversation.length) {
            userTexts = searchPersist.justAskConversation
                .filter(item => item.type === 'user')
                .map(item => encodeURIComponent(item.text))
                .join(',');
        }
        userTexts = userTexts.length ? userTexts?.concat(',').concat(encodeURIComponent(searchText)) : encodeURIComponent(searchText);
        getJustAskResponse(dispatch, searchPersist, settings, user.user_id, justAsk, filter, userTexts)
        setSearchText('');
    }

    const highlightMostRecentAI = () => {
        const mostRecentAI = conversation.slice().reverse().findIndex(message => message.type === 'ai');
        if (mostRecentAI !== -1) {
            // Adjust the index to match the original conversation array
            setHighlightIndex(conversation.length - 1 - mostRecentAI);
        }
    };

    const handlePromptClick = (index) => {
        const selectedMessage = conversation[index];
        setSearchText(selectedMessage.text);
        dispatch(clearMessagesAfterIndex(index));
        handleSearch();
    };

    const handleEmojiClick = (e, selectedEmoji, jAskResponse) => {
        e.preventDefault(); // Prevent the default behavior of the anchor tag
        if (selectedEmoji === 'happy') {
            const payload = {
                narrative: {
                    labels: ["narrative-good"],
                    request_id: jAskResponse.request_id
                }
            }
            sendFeedback(dispatch, user.user_id, payload, selectedEmoji)
            const payload2 = {
                query: {
                    labels: ["query-good"],
                    request_id: jAskResponse.request_id
                }
            }
            sendFeedback(dispatch, user.user_id, payload2, selectedEmoji)
        } else {
            setRequestId(jAskResponse.request_id)
            setModal(!modal)
        }
    };

    const handleCancel = () => {
        setRequestId('')
        setModal(!modal)
    }

    return (
        <Container className="leftPanel-content d-flex flex-column h-100">
            <Row>
                <Col className="mt-3">
                    <h5>Just Ask</h5>
                </Col>
            </Row>
            {!conversation?.length > 0 && (
                <>
                    <Row>
                        <Col className="text-center">
                            <GoLightBulb className="react-icons" size={24} />
                            <p className="d-block mb-0">Examples</p>
                        </Col>
                    </Row>
                    <Row className="flex-grow-1">
                        <Col className="examples text-center">
                            {examples?.length > 0 ? (
                                examples.map((example, index) => (
                                    <Button
                                        id={`example-${index}`}
                                        key={index}
                                        onClick={() => handleExampleClick(example)}
                                        className="example-btn mb-2 text-start rounded w-100"
                                        color="primary"
                                        outline
                                    >
                                        {example.example} →
                                    </Button>
                                ))
                            ) : searchPersist?.noResultForExamples ? (
                                <p>No examples available at the moment. Please try again later.</p>
                            ) : null
                            }
                        </Col>
                    </Row>
                </>
            )}
            {conversation?.length > 0 && (
                <Row className="flex-grow-1 chat-screen">
                    <Col className="chat-container">
                        {conversation.map((message, index) => (
                            <>
                                <div
                                    id={`${message.type}-${index}`}
                                    key={index}
                                    className={`chat-bubble ${message.type === 'user' ? 'user-bubble' : 'ai-bubble'} ${highlightIndex === index ? 'ai-bubble-latest' : ''}`}
                                // onClick={() => message.type === 'user' && handlePromptClick(index)}
                                >
                                    {message.text}
                                    {message.text === '' ?
                                        <div className='loading'>
                                            <span class="dot"></span>
                                            <span class="dot"></span>
                                            <span class="dot"></span>
                                        </div> : ''}
                                </div>
                                {settings.preferences?.justask_feedback && message.type === 'ai' && highlightIndex === index && message.text !== '' &&
                                    <p className="emoji-section">
                                        <Link id="happy" href="#" className={`${emoji === 'sad' ? 'unselected-emoji' : ''}`} onClick={(e) => handleEmojiClick(e, 'happy', message)}>&#128515;</Link>
                                        <Link id="sad" href="#" className={`${emoji === 'happy' ? 'unselected-emoji' : ''}`} onClick={(e) => handleEmojiClick(e, 'sad', message)}>&#128542;</Link>
                                    </p>
                                }
                            </>
                        ))}
                    </Col>
                </Row>
            )}
            <FeedbackModal
                handleCancel={handleCancel}
                isOpen={modal}
                request_id={requestId}>
            </FeedbackModal>
            <Row className="mt-auto">
                <InputGroup>
                    <Input
                        id="justask-input"
                        type="text"
                        placeholder="Just Ask ..."
                        aria-label="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={handleKeyPress}
                        className="custom-input"
                        disabled={loading}
                    />
                    <InputGroupText id='justask-search' className="icon-background" onClick={handleSearch}>
                        <FaPaperPlane className='react-icons' />
                    </InputGroupText>
                </InputGroup>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center my-3">
                    <Button id='justask-newsearch'
                        className="new-search-btn w-100"
                        onClick={handleNewSearch}
                    >
                        Start new search
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default LeftPanel;

