import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CommonPlaceholder from '../../details/framework/commonPlaceholder';
import CompanyRow from './companyRow';
import { groupNotesbyUpdatedDate } from '../../../utils';

const CompanyList = (props) => {
  const search = useSelector((state) => state.search);
  const justask = useSelector((state) => state.justAsk);
  const myleads = useSelector((state) => state.myleads);
  const leagueTableState = useSelector((state) => state.leagueTable);
  const searchPersist = useSelector((state) => state.searchPersist);
  const [myLeadsListUpdatedDate, setMyLeadsListUpdatedDate] = useState([]);

  const isLeagueTable = props?.prevNextList === 'LeagueTableList';

  useEffect(() => {
    if (props?.prevNextList === 'myLeadsUpdates' && !myleads?.isListView) {
      setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.updatesSearchList || [], props?.prevNextList));
    } else if (
      props?.prevNextList === 'myLeadsHistory' &&
      searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label.toLowerCase() === 'last viewed' &&
      !myleads?.isListView
    ) {
      setMyLeadsListUpdatedDate(groupNotesbyUpdatedDate(myleads?.searchList || [], props?.prevNextList));
    } else {
      setMyLeadsListUpdatedDate([]);
    }
  }, [myleads?.searchList, myleads?.isListView, myleads?.updatesSearchList]);

  const renderCompanyList = (list, showRelatedEntitiesMode = false, isHierarchy = false) => {
    return list?.length
      ? list.map((company, index) => (
          <li className="list-group-item" id={company.company_id} key={company.company_id}>
          <CompanyRow
            company={company}
            prevNextList={props.prevNextList}
            isShowRelatedEntitiesMode={showRelatedEntitiesMode}
            index={index}
            myHoverList={props.myHoverList}
            isHierarchy={isHierarchy}
            activeTab={props.activeTab}
          />
        </li>
      ))
      : <CommonPlaceholder />;
  };

  const renderGroupedCompanyList = () => {
    return (props?.prevNextList === 'myLeadsUpdates' && !myleads?.isListView) ||
    (props?.prevNextList === 'myLeadsHistory' &&
      searchPersist?.leadsSort?.history?.[searchPersist?.leadsAppliedContext?.history]?.label.toLowerCase() === 'last viewed'
      && !myleads?.isListView) ?
      (Object.entries(myLeadsListUpdatedDate).map(([key, array]) => (
        <div className="note-listing pb-2" key={key}>
          <span className="update-date">{key}</span>
          {renderCompanyList(array, true)}
        </div>
      ))) :
      (props?.prevNextList === 'myLeadsUpdates') ?
      renderCompanyList(myleads?.updatesSearchList, true)
      : renderCompanyList(myleads?.searchList, true)
  };

  if (isLeagueTable) {
    return renderCompanyList(leagueTableState?.searchList);
  }

  const renderSearchOrJustAskList = (source) => {
    if (props.entityFlag && source?.listOfSelectedObjectIds?.length) {
      return renderCompanyList(source.listOfSelectedObjectIds, true);
    } else if (source?.clusterList?.leadsFlags?.length) {
      return renderCompanyList(source.clusterList.leadsFlags, false, true);
    } else if (source?.searchList?.length) {
      return renderCompanyList(source.searchList);
    }
    return <CommonPlaceholder />;
  };

  switch (props?.prevNextList) {
    case 'searchList':
    case 'selectedSearchList':
      return renderSearchOrJustAskList(search);

    case 'jAskSearchList':
    case 'selectedjAskSearchList':
      return renderSearchOrJustAskList(justask);

    case 'myLeadsUpdates':
    case 'myLeadsHistory':
      return renderGroupedCompanyList();

    default:
      return renderCompanyList(myleads?.searchList || []);
  }
};

export default CompanyList;
