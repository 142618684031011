import React from 'react'
import MyLeadsSortOrder from './sortOrder'

const MyLeadsSort = (props) => {
    return (
        <div className="sort-results sort-result-section me-3">
            <MyLeadsSortOrder activeTab={props.activeTab} />
        </div>
    )
}

export default MyLeadsSort