import React from 'react'
import SortOrder from './sortOrder'
import { useSelector } from 'react-redux'
import { TOURPROJECTLISTVIEW } from '../../../utils/tour'

const Sort = () => {
    const filter=useSelector((state) => state.filter)
    return (
        <div className="sort-results sort-result-section me-3"  data-intro={!filter.isFilterActive ? TOURPROJECTLISTVIEW.sortorder.intro : null} data-position={TOURPROJECTLISTVIEW.sortorder.position}data-class={TOURPROJECTLISTVIEW.sortorder.class}>
            <SortOrder />
        </div>
    )
}

export default Sort