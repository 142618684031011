import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import SelectorControlAddTag from './selectorControlAddTag'

const TagsModal = (props) => {
    return (
        <div className="button-blocks">
            <Modal isOpen={props.showModal} className="tags-modal">
                <ModalHeader>Tags<button id="btn-cancel" type="primary" className="btn-cancel" onClick={props.handleTagsCancel}>Cancel</button></ModalHeader>
                <ModalBody className="save-model-body">
                    <SelectorControlAddTag />
                </ModalBody>
                <ModalFooter>
                    {
                        props?.selectedTags?.selectedTags?.length > 0 ? (
                            <button id="btn-done" className="primary-btn btn-save-delete" onClick={props.handleTagsSubmit}>Done</button>
                        ) : (
                            <button id="btn-done" className="primary-btn btn-save-delete disabled">Done</button>
                        )
                    }
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default TagsModal